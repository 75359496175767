import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { shallowEqual } from "react-redux";
import GoalCard from "src/features/goals/GoalCard";
import GoalEditDialog from "src/features/goals/GoalEditDialog";
import { useAppSelector } from "src/hooks/stateHooks";

type Props = {
  userId: string;
  defaultExpanded?: boolean;
  freeFloating?: boolean;
  condensed?: boolean;
};

export default function ProgressPanel({
  userId,
  defaultExpanded = false,
  freeFloating = false,
  condensed,
}: Props) {
  const client = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId ? state.client.client : undefined,
    shallowEqual,
  );
  const [goalEditDialogOpen, setGoalEditDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(defaultExpanded);

  if (!client) {
    return null;
  }

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      sx={{ overflow: "hidden", backgroundColor: "transparent" }}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      expanded={condensed ? false : expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🙂{!condensed ? " Progress" : ""}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <>
          {!client.goal && !client.account.dashboard.date_onboarded && (
            <Box sx={{ px: 1 }}>
              <Button
                fullWidth
                sx={{ my: 1 }}
                startIcon={<EditCalendarRoundedIcon />}
                onClick={() => {
                  setGoalEditDialogOpen(true);
                }}
              >
                Set goal schedule
              </Button>
              <GoalEditDialog
                client={client}
                open={goalEditDialogOpen}
                onClose={() => {
                  setGoalEditDialogOpen(false);
                }}
              />
            </Box>
          )}
          <GoalCard client={client} sx={{ my: 1, mx: 1 }} />
        </>
      </AccordionDetails>
    </Accordion>
  );
}
