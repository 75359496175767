type Props = {
  width?: number | null;
  height?: number | null;
  url: string;
  thumbnailUrl: string;
  combineBottom: boolean;
  isFromMe: boolean;
};

export default function VideoMessage({
  width,
  height,
  url,
  thumbnailUrl,
  combineBottom,
  isFromMe,
}: Props) {
  if (width && height) {
    return (
      <video
        controls
        width={250}
        height={(250 / width) * height}
        style={{
          borderRadius: isFromMe
            ? `12px 12px ${!combineBottom ? "2px" : "12px"} 12px`
            : `12px 12px 12px ${!combineBottom ? "2px" : "12px"}`,
          display: "block",
        }}
        poster={thumbnailUrl}
      >
        <source src={url} />
      </video>
    );
  } else {
    return (
      <video
        controls
        height={150}
        style={{
          width: "100%",
          borderRadius: isFromMe
            ? `12px 12px ${!combineBottom ? "2px" : "12px"} 12px`
            : `12px 12px 12px ${!combineBottom ? "2px" : "12px"}`,
          display: "block",
        }}
      >
        <source src={url} />
      </video>
    );
  }
}
