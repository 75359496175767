import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import {
  Box,
  CardActionArea,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import type { HabitTask, HabitWeek } from "@trainwell/types";
import { format } from "date-fns";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HabitDot from "src/components/misc/HabitDot";
import HabitTaskPopover from "src/components/misc/HabitTaskPopover";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getHabitColor, isHabitDayInPast } from "src/lib/habits";
import {
  selectHabitTaskById,
  selectWeekPlanById,
  setHabitOverrideNotes,
} from "src/slices/clientSlice";
import WorkoutPreviewPopover from "../phase-column/WorkoutPreviewPopover";

type Props = {
  habitTask?: HabitTask;
  habitWeek: HabitWeek;
  isEditMode: boolean;
  dayIndex: number;
  weekPlanId: string;
  isLast?: boolean;
  forceTaskNames?: string[];
};

export default function HabitTaskCell({
  habitTask,
  habitWeek,
  isEditMode,
  dayIndex,
  weekPlanId,
  isLast,
  forceTaskNames,
}: Props) {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isInPast = useAppSelector((state) =>
    isHabitDayInPast(selectWeekPlanById(state, weekPlanId)!.date, dayIndex),
  );
  const dateActuallyCompletedOn = useAppSelector((state) =>
    habitTask?.habit_task_id_completed
      ? selectHabitTaskById(state, habitTask.habit_task_id_completed)
          ?.date_completed
      : undefined,
  );

  const name = forceTaskNames?.at(dayIndex) || habitWeek.name;
  const disableTruncation = habitWeek.type === "progress_metric_measurement";

  if (!habitTask) {
    if (isEditMode) {
      return (
        <TextField
          sx={{ my: 1, fontSize: "0.8rem" }}
          size="small"
          variant="standard"
          multiline
          placeholder={habitWeek.notes_coach_default ?? "Custom notes..."}
          defaultValue={habitWeek.notes_coach_days[dayIndex]}
          inputProps={{ sx: { fontSize: "0.8rem" } }}
          disabled={isInPast}
          onChange={(event) => {
            dispatch(
              setHabitOverrideNotes({
                planId: weekPlanId,
                habitId: habitWeek.id,
                dayIndex: dayIndex,
                newNotes: event.target.value,
              }),
            );
          }}
        />
      );
    } else {
      return (
        <Tooltip
          title={habitWeek.notes_coach_days[dayIndex] ?? ""}
          disableInteractive
        >
          <Box
            sx={{
              p: 0.5,
              display: "flex",
              alignItems: "center",
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            <HabitDot type={habitWeek.type} sx={{ mr: 0.5, flexShrink: 0 }} />
            <Typography
              variant="body2"
              sx={{
                overflow: disableTruncation ? undefined : "hidden",
                whiteSpace: disableTruncation ? undefined : "nowrap",
                textOverflow: disableTruncation ? undefined : "ellipsis",
              }}
            >
              {name}
            </Typography>
            {habitWeek.notes_coach_days[dayIndex] && (
              <ChatBubbleRoundedIcon
                sx={{
                  fontSize: "inherit",
                }}
              />
            )}
          </Box>
        </Tooltip>
      );
    }
  } else if (habitTask) {
    return (
      <>
        <Tooltip
          title={
            habitTask.habit_task_id_completed
              ? `Completed on ${
                  dateActuallyCompletedOn
                    ? format(
                        new Date(dateActuallyCompletedOn as string),
                        "MMM do",
                      )
                    : "different day"
                }`
              : (habitTask.notes_client ?? "")
          }
          disableInteractive
        >
          <CardActionArea
            onClick={(event) => {
              if (habitTask.workout_log_id) {
                navigate(`/clients/${userId}/logs/${habitTask.workout_log_id}`);
              } else {
                setAnchorEl(event.currentTarget);
              }
            }}
            onContextMenu={(event) => {
              event.preventDefault();

              setAnchorEl(event.currentTarget);
            }}
            sx={{
              // backgroundColor: getHabitColorSurface(habitTask.type, theme),
              opacity: habitTask.habit_task_id_completed ? 0.5 : undefined,
              backgroundColor: (theme) =>
                habitTask.date_completed || habitTask.habit_task_id_completed
                  ? alpha(getHabitColor(habitWeek.type, theme)!, 0.33)
                  : theme.palette.background.paper,
              background: (theme) =>
                !habitTask.date_completed &&
                habitTask.dates_steps_completed?.some((date) => Boolean(date))
                  ? `repeating-linear-gradient(
              45deg,
              transparent,
              transparent 10px,
              ${alpha(getHabitColor(habitWeek.type, theme)!, 0.33)} 10px,
              ${alpha(getHabitColor(habitWeek.type, theme)!, 0.33)} 20px
            )`
                  : undefined,
              p: 0.5,
              borderBottom: isLast ? 0 : 1,
              borderColor: "divider",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                maxWidth: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "100%",
                }}
              >
                <HabitDot
                  type={habitWeek.type}
                  sx={{ mr: 0.5, flexShrink: 0 }}
                  complete={Boolean(
                    habitTask.date_completed ||
                      habitTask.habit_task_id_completed,
                  )}
                />
                <Typography
                  variant="body2"
                  sx={{
                    overflow:
                      habitWeek.name === habitTask.name ? "hidden" : undefined,
                    whiteSpace:
                      habitWeek.name === habitTask.name ? "nowrap" : undefined,
                    textOverflow:
                      habitWeek.name === habitTask.name
                        ? "ellipsis"
                        : undefined,
                  }}
                >
                  {habitTask.name}
                </Typography>
              </Box>
              {habitTask.notes_client && (
                <EmojiPeopleRoundedIcon sx={{ fontSize: "inherit" }} />
              )}
            </Box>
          </CardActionArea>
        </Tooltip>
        {habitTask.workout_id
          ? anchorEl && (
              <WorkoutPreviewPopover
                anchorEl={anchorEl}
                workoutId={habitTask.workout_id}
                onClose={() => {
                  setAnchorEl(null);
                }}
              />
            )
          : anchorEl && (
              <HabitTaskPopover
                habitTask={habitTask}
                anchorEl={anchorEl}
                onClose={() => {
                  setAnchorEl(null);
                }}
              />
            )}
      </>
    );
  }
}
