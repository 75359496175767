import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button } from "@mui/material";
import ControlBar from "src/components/misc/ControlBar";
import MetricsPage from "src/features/trainer-metrics/MetricsPage";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export default function CoachMetricsPage() {
  const trainer = useAppSelector(selectPrimaryTrainer);

  if (!trainer) {
    return null;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <MetricsPage trainerId={trainer.trainer_id} />
    </Box>
  );
}
