import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Avatar,
  Card,
  CardActionArea,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectClientsWorkoutValidity } from "src/slices/clientSlice";
import { selectWorkoutById, updateWorkout } from "src/slices/phasesSlice";
import WorkoutPreviewPopover from "../phase-column/WorkoutPreviewPopover";

type Props = {
  workoutId: string;
};

function WorkoutExtraCell({ workoutId }: Props) {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, workoutId),
  );
  const workoutValidity = useAppSelector((state) =>
    selectClientsWorkoutValidity(state, workout?.workout_id ?? ""),
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (!workout) {
    return <>Loading</>;
  }

  return (
    <>
      <Tooltip disableInteractive title={workoutValidity?.message}>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: (theme) =>
              workoutValidity?.status === "error"
                ? theme.palette.errorSurface.main
                : undefined,
          }}
        >
          <CardActionArea
            href={`/clients/${userId}/workouts/${workout.workout_id}`}
            onContextMenu={(event) => {
              event.preventDefault();

              setAnchorEl(event.currentTarget);
            }}
            sx={{
              px: 1,
              py: 0.25,
              display: "flex",
              alignItems: "center",
            }}
            draggable={false}
          >
            <Typography variant="body2">{workout?.name}</Typography>
            <IconButton
              sx={{ ml: 1 }}
              color="error"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();

                dispatch(
                  updateWorkout({
                    workout_id: workout.workout_id,
                    is_extra: false,
                  }),
                );
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              size="small"
            >
              <RemoveCircleRoundedIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </CardActionArea>
        </Card>
      </Tooltip>
      {anchorEl && (
        <WorkoutPreviewPopover
          anchorEl={anchorEl}
          workoutId={workout.workout_id}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      )}
    </>
  );
}

export default function WorkoutExtraCellDraggable(props: Props) {
  const ref = useRef(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, props.workoutId),
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "workout_extra",
      workoutId: props.workoutId,
    };

    return draggable({
      element: element,
      getInitialData: () => data,
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          getOffset: pointerOutsideOfPreview({
            x: "16px",
            y: "8px",
          }),
          render({ container }) {
            setPreviewContainer(container);
          },
        });
      },
    });
  }, [props.workoutId]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div ref={ref}>
          <WorkoutExtraCell {...props} />
        </div>
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview
              text={workout?.name ?? ""}
              icon={
                <Avatar
                  sx={{
                    backgroundColor: (theme) => theme.palette.blue.main,
                    width: 20,
                    height: 20,
                    borderRadius: "6px",
                  }}
                >
                  <FitnessCenterRoundedIcon
                    sx={{
                      fontSize: 16,
                    }}
                  />
                </Avatar>
              }
            />,
            previewContainer,
          )
        : null}
    </>
  );
}
