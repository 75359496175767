import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import LabelRoundedIcon from "@mui/icons-material/LabelRounded";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import SearchField from "src/components/misc/SearchField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getSmartTagDetails } from "src/lib/phaseTags";
import {
  selectPhaseTemplatesByTag,
  setTemplateNavigation,
} from "src/slices/phaseTemplatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import PhaseRequestDialog from "./PhaseRequestDialog";
import PhaseTemplateCell from "./PhaseTemplateCell";
import WorkoutTemplateCell from "./WorkoutTemplateCell";

export default function TagPage() {
  let { tagId } = useParams();
  const memoryTagId = useAppSelector((state) => state.phaseTemplates.openTagId);

  if (memoryTagId) {
    tagId = memoryTagId;
  }

  const currentTab = useAppSelector((state) => state.phaseTemplates.currentTab);
  const phaseTemplates = useAppSelector((state) =>
    selectPhaseTemplatesByTag(state, tagId ?? ""),
  );
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const [search, setSearch] = useState("");

  const { filteredPhaseTemplates, filteredWorkoutTemplates } = useMemo(() => {
    let newPhaseTemplates = [...phaseTemplates];

    if (currentTab === "trainwell") {
      newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.trainer_id === "copilot";
      });
    } else {
      newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.trainer_id === trainerId;
      });
    }

    if (search) {
      newPhaseTemplates = newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.name.toLowerCase().includes(search.toLowerCase());
      });
    }

    newPhaseTemplates.sort((a, b) => {
      // Sort pinned to the top
      if (a.is_pinned && !b.is_pinned) {
        return -1;
      } else if (!a.is_pinned && b.is_pinned) {
        return 1;
      }

      // Sort by date updated
      return ((b.date_updated ?? b.date_created) as string).localeCompare(
        (a.date_updated ?? a.date_created) as string,
      );
    });

    return {
      filteredPhaseTemplates: newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.type === "multiple";
      }),
      filteredWorkoutTemplates: newPhaseTemplates.filter((phaseTemplate) => {
        return phaseTemplate.type === "single";
      }),
    };
  }, [phaseTemplates, search, currentTab, trainerId]);

  useEffect(() => {
    setSearch("");
  }, [tagId]);

  return (
    <>
      <Box
        sx={{
          px: 3,
          height: "100%",
          overflowY: "auto",
        }}
      >
        <Virtuoso
          data={[...filteredPhaseTemplates, ...filteredWorkoutTemplates]}
          components={{
            Header: Header as any,
          }}
          context={{ search, setSearch }}
          style={{ height: "100%" }}
          itemContent={(index, item) => {
            if (item.type === "multiple") {
              return <PhaseTemplateCell phaseTemplateId={item._id} />;
            } else {
              return <WorkoutTemplateCell phaseTemplateId={item._id} />;
            }
          }}
        />
      </Box>
    </>
  );
}

type HeaderProps = {
  context: {
    search: string;
    setSearch: (value: string) => void;
  };
};

function Header({ context: { search, setSearch } }: HeaderProps) {
  const dispatch = useAppDispatch();
  let { tagId } = useParams();
  const memoryTagId = useAppSelector((state) => state.phaseTemplates.openTagId);

  if (memoryTagId) {
    tagId = memoryTagId;
  }

  const openForUserId = useAppSelector(
    (state) => state.phaseTemplates.openForUserId,
  );
  const isDialog = Boolean(openForUserId);
  const currentTab = useAppSelector((state) => state.phaseTemplates.currentTab);
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);

  const tagDetails = getSmartTagDetails(tagId || "");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 1,
          pt: 1,
        }}
      >
        <Button
          variant="text"
          color="primary"
          startIcon={<ExtensionRoundedIcon />}
          href={isDialog ? "" : "/template-library"}
          onClick={() => {
            if (isDialog) {
              dispatch(
                setTemplateNavigation({
                  openFolderId: null,
                  openTagId: null,
                }),
              );
            }
          }}
        >
          {currentTab === "trainer" ? "My library" : "trainwell library"}
        </Button>
        <ChevronRightRoundedIcon
          fontSize="small"
          sx={{ mx: 1, color: (theme) => theme.palette.text.secondary }}
        />
        {tagDetails.icon ? (
          <tagDetails.icon.type
            fontSize="small"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          />
        ) : (
          <LabelRoundedIcon
            fontSize="small"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          />
        )}
        <Typography sx={{ ml: 1 }}>{tagDetails.label}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {tagDetails.icon ? (
          <tagDetails.icon.type
            sx={{ color: (theme) => theme.palette.text.secondary }}
          />
        ) : (
          <LabelRoundedIcon
            sx={{ color: (theme) => theme.palette.text.secondary }}
          />
        )}
        <Typography variant="h1" sx={{ ml: 1 }}>
          {tagDetails.label}
        </Typography>
      </Box>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{ mb: 2, mt: 2, alignItems: "center" }}
      >
        <SearchField
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
          sx={{ maxWidth: "300px" }}
        />
        {currentTab === "trainwell" && (
          <Button
            size="small"
            variant="text"
            startIcon={<CampaignRoundedIcon />}
            onClick={() => {
              setRequestDialogOpen(true);
            }}
          >
            Request
          </Button>
        )}
      </Stack>
      <PhaseRequestDialog
        open={requestDialogOpen}
        onClose={() => {
          setRequestDialogOpen(false);
        }}
      />
    </>
  );
}
