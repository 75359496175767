import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { isBlank } from "@trainwell/features";
import { endOfToday, isFuture } from "date-fns";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { updateClientLocal } from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";
import { DialogTitleWithClose } from "./DialogTitleWithClose";

type Props = {
  open: boolean;
  onClose: () => void;
};

export function TrainerBannerDialog({ open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const currentClient = useAppSelector((state) => state.client.client);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [banner, setBanner] = useState(
    trainer?.banner_custom && isFuture(trainer.banner_custom.date_active_until)
      ? trainer.banner_custom.text
      : "",
  );
  const [date, setDate] = useState<Date | null>(endOfToday());

  useEffect(() => {
    if (open) {
      setDate(endOfToday());
      setBanner(
        trainer?.banner_custom &&
          isFuture(trainer.banner_custom.date_active_until)
          ? trainer.banner_custom.text
          : "",
      );
    }
  }, [open]);

  function handleClose() {
    onClose();
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitleWithClose onClose={handleClose}>
          Set chat banner for ALL clients
        </DialogTitleWithClose>
        <DialogContent>
          <DialogContentText sx={{ mb: 4 }}>
            Your chat banner is shown at the top of{" "}
            <b>ALL of your client&apos;s chats</b> in their app. Trainwell sets
            it automatically for PTO and holidays. It&apos;s a good spot to set
            special expectations for clients who are trying to message you.
          </DialogContentText>
          <TextField
            multiline
            fullWidth
            label="Chat banner"
            value={banner}
            onChange={(event) => {
              setBanner(event.target.value.substring(0, 120));
            }}
            helperText={`${banner.length} / 120`}
            sx={{ mb: 2 }}
          />
          <TimePicker
            label="Clear chat banner at"
            value={date}
            onChange={(newValue) => {
              setDate(newValue ?? new Date());
            }}
            slotProps={{
              field: {
                sx: {
                  width: "100%",
                },
              },
              textField: {
                helperText: "Defaults to end of today",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            variant="text"
            onClick={() => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer?.trainer_id ?? "",
                  banner_custom: null,
                }),
              )
                .unwrap()
                .then(() => {
                  if (currentClient) {
                    api.clients.getOne(currentClient.user_id).then((client) => {
                      dispatch(
                        updateClientLocal({
                          user_id: currentClient.user_id,
                          banner_coach: client.banner_coach,
                        }),
                      );
                    });
                  }
                });

              handleClose();
            }}
          >
            Clear my banner
          </Button>
          <LoadingButton
            variant="contained"
            startIcon={<SaveRoundedIcon />}
            onClick={async () => {
              console.log(date);
              if (!trainer || !date) {
                return;
              }

              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  banner_custom: {
                    text: banner,
                    date_active_until: date,
                  },
                }),
              )
                .unwrap()
                .then(() => {
                  if (currentClient) {
                    api.clients.getOne(currentClient.user_id).then((client) => {
                      dispatch(
                        updateClientLocal({
                          user_id: currentClient.user_id,
                          banner_coach: client.banner_coach,
                        }),
                      );
                    });
                  }
                });

              handleClose();
            }}
            disabled={isBlank(banner)}
          >
            Set banner
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
