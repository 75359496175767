import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useCreateAudit } from "@trainwell/features";
import type { NotificationMessage as NotificationMessageType } from "@trainwell/types";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useIsInRolexTest } from "src/hooks/useIsInRolexTest";
import { getNotificationTitle } from "src/lib/message";
import { updateMessage } from "src/slices/chatSlice";
import { setAuditPanelOpen } from "src/slices/clientSlice";

type Props = {
  message: NotificationMessageType;
};

export default function NotificationMessage({ message }: Props) {
  const dispatch = useAppDispatch();
  const realTrainerId = useAppSelector(
    (state) => state.trainer.trainer?.trainer_id,
  );
  const isInRolexTest = useIsInRolexTest();
  const createAudit = useCreateAudit();
  const dateString = format(
    new Date(message.send_date),
    "E, MMM d yyyy, h:mm a",
  );

  let readDateString = "Unread";
  if (message.read_date) {
    readDateString = format(
      new Date(message.read_date),
      "E, MMM d yyyy, h:mm a",
    );
  }

  if (
    message.notification_type === "progress_metric_logged" ||
    message.notification_type === "progress_metric_logged_many"
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: 1,
        width: "100%",
      }}
    >
      <Tooltip
        title={
          <>
            {dateString}
            <Typography sx={{ fontSize: 10 }}>
              Read: {readDateString}
            </Typography>
          </>
        }
        disableInteractive
        placement={"left"}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              whiteSpace: "pre-line",
              wordWrap: "break-word",
              textAlign: "center",
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {message.notification_title ||
              getNotificationTitle(message.notification_type)}
          </Typography>
          {message.text && (
            <Typography
              sx={{
                whiteSpace: "pre-line",
                wordWrap: "break-word",
                fontSize: 14,
                textAlign: "center",
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {message.text}
            </Typography>
          )}
        </Box>
      </Tooltip>
      {isInRolexTest &&
        message.notification_type === "cancellation_requested" &&
        !message.content?.audit_id && (
          <Button
            size="small"
            sx={{ mt: 1 }}
            onClick={() => {
              createAudit.mutate(
                {
                  data: {
                    subject_user_id: message.from_id,
                    auditor_trainer_id: realTrainerId!,
                    type: "client_cancellation",
                    text: `In what ways did you make this client feel uniquely cared for?

Anything you could have done to make this client feel more cared for?

What's your biggest opportunity for growth that you learned from this cancelation?`,
                  },
                },
                {
                  onSuccess: (audit) => {
                    dispatch(
                      updateMessage({
                        messageId: message.message_id,
                        auditId: audit.id,
                      }),
                    );
                  },
                },
              );
              dispatch(setAuditPanelOpen(true));

              setTimeout(() => {
                document
                  .getElementById("audit-panel")
                  ?.scrollIntoView({ behavior: "smooth", block: "center" });
              }, 50);
            }}
          >
            Start cancellation audit
          </Button>
        )}
    </Box>
  );
}
