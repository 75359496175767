import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { logClick } from "src/slices/analyticsSlice";
import { ClientColumn } from "../client-column/ClientColumn";
import HabitPanel from "../habit-column/HabitPanel";
import PhaseBuilder from "../phase-builder/PhaseBuilder";
import PhaseWorkoutPanel from "./PhaseWorkoutPanel";

export const SelectorColumn = memo(function SelectorColumn() {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState<
    "phases_workouts" | "habits" | "client" | "phase_builder"
  >("client");
  const { logId, workoutId } = useParams();
  const isPhaseSelected = useAppSelector(
    (state) => state.phases.phaseEditing !== null,
  );

  const isWorkoutOrLogOpen = Boolean(workoutId || logId);

  useEffect(() => {
    if (isWorkoutOrLogOpen) {
      if (isPhaseSelected) {
        setCurrentTab("phase_builder");
      } else {
        // setCurrentTab("client");
      }
    } else if (!isWorkoutOrLogOpen && currentTab === "phase_builder") {
      setCurrentTab("client");
    }
  }, [isWorkoutOrLogOpen]);

  useEffect(() => {
    if (isWorkoutOrLogOpen) {
      if (isPhaseSelected) {
        setCurrentTab("phase_builder");
      } else if (!isPhaseSelected) {
        setCurrentTab("phases_workouts");
      }
    }
  }, [isPhaseSelected]);

  return (
    <>
      {isPhaseSelected && !isWorkoutOrLogOpen && (
        <Box
          sx={{
            width: 220,
            flexShrink: 0,
            height: "calc(100vh - 38px)",
            // pt: "38px",
            borderRight: 1,
            borderColor: "divider",
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <PhaseBuilder onClose={() => {}} />
        </Box>
      )}
      <TabContext value={currentTab}>
        <Box
          sx={{
            height: "calc(100vh - 38px)",
            borderRight: 1,
            borderColor: "divider",
            backgroundColor: (theme) => theme.palette.background.default,
            display: "flex",
            flexDirection: "column",
            width: "250px",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            <TabList
              onChange={(_, newValue) => {
                setCurrentTab(newValue);
              }}
              sx={{ minHeight: "32px" }}
              // scrollButtons="auto"
              // variant="scrollable"
            >
              <Tab
                label={"Client"}
                value="client"
                sx={{ py: 1, minHeight: "32px" }}
              />
              {isPhaseSelected && isWorkoutOrLogOpen && (
                <Tab
                  label={"Phase"}
                  value="phase_builder"
                  sx={{ py: 1, minHeight: "32px" }}
                />
              )}
              {(!isWorkoutOrLogOpen || !isPhaseSelected) && (
                <Tab
                  label={"Phases"}
                  value="phases_workouts"
                  onClick={() => {
                    dispatch(
                      logClick({
                        elementName: "client.phases_tab",
                      }),
                    );
                  }}
                  sx={{ py: 1, minHeight: "32px" }}
                />
              )}
              {!isWorkoutOrLogOpen && (
                <Tab
                  label={"Habits"}
                  value="habits"
                  sx={{ py: 1, minHeight: "32px" }}
                  onClick={() => {
                    dispatch(
                      logClick({
                        elementName: "client.habits_tab",
                      }),
                    );
                  }}
                />
              )}
            </TabList>
          </Box>
          <TabPanel
            value="phases_workouts"
            sx={{
              p: 0,
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            <PhaseWorkoutPanel />
          </TabPanel>
          <TabPanel value="habits" sx={{ p: 0, flex: 1, overflowY: "auto" }}>
            <HabitPanel />
          </TabPanel>
          <TabPanel value="client" sx={{ p: 0, flex: 1, overflowY: "auto" }}>
            <ClientColumn />
          </TabPanel>
          <TabPanel
            value="phase_builder"
            sx={{ p: 0, flex: 1, overflowY: "auto" }}
          >
            <PhaseBuilder onClose={() => {}} />
          </TabPanel>
        </Box>
      </TabContext>
    </>
  );
});
