import { useTests } from "@trainwell/features";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export function useIsInDailyMovementTest() {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { data: tests } = useTests();

  const dailyMovementTest = tests?.find((test) =>
    test.variants.some((v) => v.id === "sep_steps_treatment"),
  );

  const otherDailyMovementTest = tests?.find((test) =>
    test.variants.some((v) => v.id === "steps_discrete_treatment"),
  );

  const isInDailyMovementTest =
    dailyMovementTest?.is_active &&
    dailyMovementTest.supported_trainer_ids?.includes(
      trainer?.trainer_id ?? "",
    );

  const isInOtherDailyMovementTest =
    otherDailyMovementTest?.is_active &&
    otherDailyMovementTest.supported_trainer_ids?.includes(
      trainer?.trainer_id ?? "",
    );

  return isInDailyMovementTest || isInOtherDailyMovementTest;
}
