import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";

const keyboardShortcuts: {
  keys: string[];
  description: string;
}[] = [
  {
    keys: ["C"],
    description: "Open / close chat",
  },
  {
    keys: ["N"],
    description: "Open / close client notes",
  },
  {
    keys: ["E"],
    description: "Open / close client equipment",
  },
  {
    keys: ["↑", "↓", "←", "→"],
    description: "Navigate to next / previous client",
  },
  {
    keys: ["T"],
    description: "Open template sidebar while in a workout",
  },
  {
    keys: ["L"],
    description: "Open template library while in client's calendar",
  },
  {
    keys: ["Esc"],
    description: "Close out of a view",
  },
];

type Props = {
  open: boolean;
  onClose: () => void;
};

export function KeyboardShortcutsDialog({ open, onClose }: Props) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitleWithClose onClose={onClose}>
        Keyboard shortcuts
      </DialogTitleWithClose>
      <DialogContent>
        <Stack spacing={2} divider={<Divider />}>
          {keyboardShortcuts.map((shortcut, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>{shortcut.description}</Typography>
              <Stack direction={"row"} spacing={1}>
                {shortcut.keys.map((key) => (
                  <Box
                    key={key}
                    sx={{
                      height: 32,
                      minWidth: 32,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 1,
                      border: 1,
                      borderColor: "divider",
                      p: 1,
                    }}
                  >
                    <Typography>{key}</Typography>
                  </Box>
                ))}
              </Stack>
            </Box>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
