import * as Sentry from "@sentry/react";
import "@uppy/audio/dist/style.min.css";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/screen-capture/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { Router } from "src/app/Router";
import {
  TRAINWELL_API_DOMAIN,
  TRAINWELL_CHAT_API_DOMAIN,
} from "src/config/config";
import { store } from "src/slices/store";
import "src/theme/theme.css";

Sentry.init({
  dsn: "https://ac27ec071dfc432095c04538fd936e9b@o505736.ingest.sentry.io/6224093",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [[400, 599]],
      failedRequestTargets: [TRAINWELL_API_DOMAIN],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    TRAINWELL_API_DOMAIN,
    TRAINWELL_CHAT_API_DOMAIN,
  ],

  // Capture Replay for 1% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: [
    "ResizeObserver loop completed with undelivered notifications.",
  ],
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <Router />
  </Provider>,
);
